* {
    box-sizing: border-box;
    font-family: "Southan";
}
.notProduction{
    text-decoration: line-through;
}
a,
button {
    transition: all .4s;
    cursor: pointer;
}

a,
a:hover,
a:active,
a:focus,
.nav-link:hover,
.nav-link:active,
.nav-link:focus {
    text-decoration: none;
}
a {
    color: #8e88f4;
}
.content-main a[href]:hover {
    cursor: pointer;
    text-decoration: underline;
}

html {
    scroll-behavior: smooth;
}

.pointer {
    cursor: pointer;
}

.table-responsive {
    border: 1px solid #E2E9F8;
}

.navbar .navbar-toggler:hover,
.navbar .navbar-toggler:active,
.navbar .navbar-toggler:focus {
    outline: 0 none;
    border: medium none;
    box-shadow: unset;
}

ul,
li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.p-multiselect {
    width: 100%;
}

.dashboard-form .p-inputtext:hover,
.dashboard-form .p-inputtext:active,
.dashboard-form .p-inputtext:focus,
.form-group .form-control:hover,
.form-group .form-control:active,
.form-group .form-control:focus {
    box-shadow: unset;
}

p {
    font-family: 'Southam Demo';
}

.modal-dialog-centered .account-structure .row.mt-4.mb-4 {
    padding: 0 15px;
}

.modal-dialog-centered .account-structure .title-section {
    padding: 0 15px;
}

.modal-dialog-centered .account-structure .tabs-btn.horizontal-tabs {
    padding: 0 30px;
}

.dashboard-form p-autocomplete {
    width: 100%;
    display: inline-block;
}

.dashboard-form p-autocomplete span,
.dashboard-form .p-inputtext {
    width: 100%;
}

.dashboard-form .p-inputtext {
    background-color: #eefefd;
    font-family: 'Gilroy', sans-serif;
}

.login .main-header,
.forgot-password .main-header,
.reset-password .main-header,
.tracking .main-header {
    display: none;
}

.small-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
}

.color-primary {
    color: #8F89F5;
}

.profile-change .primary-text {
    color: #8E88F4;
}

.common-wrapper {
    margin-top: 100px;
}

.account-structure.common-wrapper {
    margin-bottom: 60px;
}

.table-common thead tr th,
.table-common tbody tr td {
    white-space: nowrap;
}

textarea.form-control {
    resize: none;
}

/* Custom Fonts Import start */
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Medium.eot');
    src: url('../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Medium.woff2') format('woff2'),
        url('../fonts/Gilroy-Medium.woff') format('woff'),
        url('../fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.eot');
    src: url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Regular.woff2') format('woff2'),
        url('../fonts/Gilroy-Regular.woff') format('woff'),
        url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Heavy.eot');
    src: url('../fonts/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Heavy.woff2') format('woff2'),
        url('../fonts/Gilroy-Heavy.woff') format('woff'),
        url('../fonts/Gilroy-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Light.eot');
    src: url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Light.woff2') format('woff2'),
        url('../fonts/Gilroy-Light.woff') format('woff'),
        url('../fonts/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-SemiBold.eot');
    src: url('../fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-SemiBold.woff2') format('woff2'),
        url('../fonts/Gilroy-SemiBold.woff') format('woff'),
        url('../fonts/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Black.eot');
    src: url('../fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Black.woff2') format('woff2'),
        url('../fonts/Gilroy-Black.woff') format('woff'),
        url('../fonts/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-ExtraBold.eot');
    src: url('../fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-ExtraBold.woff2') format('woff2'),
        url('../fonts/Gilroy-ExtraBold.woff') format('woff'),
        url('../fonts/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-UltraLight.eot');
    src: url('../fonts/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-UltraLight.woff2') format('woff2'),
        url('../fonts/Gilroy-UltraLight.woff') format('woff'),
        url('../fonts/Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Thin.eot');
    src: url('../fonts/Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Thin.woff2') format('woff2'),
        url('../fonts/Gilroy-Thin.woff') format('woff'),
        url('../fonts/Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.eot');
    src: url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Bold.woff2') format('woff2'),
        url('../fonts/Gilroy-Bold.woff') format('woff'),
        url('../fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Southam Demo';
    src: url('../fonts/SouthamDemo.eot');
    src: url('../fonts/SouthamDemo.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SouthamDemo.woff2') format('woff2'),
        url('../fonts/SouthamDemo.woff') format('woff'),
        url('../fonts/SouthamDemo.ttf') format('truetype'),
        url('../fonts/SouthamDemo.svg#SouthamDemo') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.text-small {
    font-size: 14px;
    margin: 0;
    line-height: 16px;
}

.text-purple {
    color: #9582FC;
}

body * {
    font-family: 'Gilroy', sans-serif;
}

body p {
    font-weight: normal;
    font-style: normal;
}

.btn:hover,
.btn:active,
.btn:focus {
    outline: 0 none;
    box-shadow: unset;
}

.btn-back {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
}

.btn-back span {
    margin-right: 10px;
    font-size: 18px;
}

.btn-orange {
    background: #FFAA00;
    font-weight: 600;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
    color: #fff;
}

.btn-orange:hover {
    color: #fff;
}

.common-pagination {
    margin-top: 30px;
}

.common-pagination .pagination {
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.table-dropdown .dropdown-item {
    line-height: 24px;
}

.common-pagination .page-item .page-link {
    padding: 0 5px;
    margin: 0 7px;
    border: medium none;
    background-color: transparent;
    color: #000;
    font-weight: 600;
    font-size: 12.5px;
    line-height: 25px;
}

.common-pagination .page-item .page-link.prev-link,
.common-pagination .page-item .page-link.next-link {
    background: #8E88F4;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 15px;
    text-transform: capitalize;
}

.common-pagination .page-item.active {
    background: #e5e4f3;
    border-radius: 4px;
}

.common-pagination a:focus {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* -webkit-appearance: none; */
}

/* Custom Fonts Import End */
.section {
    max-width: 1920px;
    margin-inline: auto;
}


/* Login Page CSS Start */
.login-section {
    background-image: url(/assets/images/login-bg.webp);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 50px;
}

.screen-logo {
    margin-bottom: 55px;
}

.login-content {
    max-width: 560px;
    margin-inline: auto;
    background-color: #fff;
    border-radius: 12px;
    padding: 54px 75px 35px;
}

.login-content .icon-group {
    position: relative;
    margin-bottom: 24px;
}

.login-content h2{
    font-size: 28px;
    font-weight: 700;
}
.login-content .screen-logo p{
    max-width: 260px;
    margin: 0px auto;
}
.icon-group .form-control {
    background-color: #EEFEFD;
    border: 1px solid rgba(127, 127, 127, 0.360195);
    border-radius: 8px;
    height: auto;
    padding: 15px 15px 15px 60px;
}

.icon-group img {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 20px;
}

.form-group .form-control.is-invalid,
.form-group .was-validated .form-control:invalid {
    border-color: #ff0000;
}

.login-btn .btn-primary {
    background: #8E88F4;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 16%);
    border-radius: 6px;
    border: 2px solid #8E88F4;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 12px 60px;
    display: inline-block;
    margin-top: 24px;
    transition: all .4s;
    text-transform: capitalize;
}

.login-btn .btn-primary:hover,
.login-btn .btn-primary:active,
.login-btn .btn-primary:focus {
    background: #5852b4;
    border: 2px solid #5852b4;
}

.forgot-link p {
    margin-top: 20px;
    margin-bottom: 0;
}

.forgot-link a {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #8E88F4;
}

.forgot-link a:hover {
    color: #5852b4;
}

.login-section .copy-right-login {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    min-width: 220px;
}

/* Login Page CSS Start */
@media screen and (max-width: 767px) {
    .login-content {
        padding: 30px 30px 30px;
    }
}

/* Commmon Forms CSS Start */
.dashboard-form .form-group label {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* text-transform: capitalize; */
    margin-bottom: 7px;
    width: 100%;
}

.dashboard-form .form-control,
.dashboard-form .p-calendar .p-inputtext {
    background-color: #EEFEFD;
    border: 1px solid #7f7f7f5c;
    border-radius: 8px;
    font-size: 15px;
    color: #000;
}

.dashboard-form .form-control {
    line-height: 28px;
}

.dashboard-form .p-calendar .p-inputtext {
    line-height: 22px;
    padding-block: 9px;
}

.p-calendar .p-inputtext:hover,
.p-calendar .p-inputtext:active,
.p-calendar .p-inputtext:focus {
    outline: 0 none;
    border: 1px solid #7f7f7f5c;
    box-shadow: unset;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #EEFEFD inset !important;
}

.dashboard-form .form-control.is-invalid,
.dashboard-form .form-control.ng-invalid {
    border-color: #ff0000;
    background: #ffdddd;
}

.error {
    position: absolute;
    top: 100%;
    left: 0;
    color: #ff0000;
    display: none;
}

.ng-dirty.ng-invalid~.error {
    display: block;
}

.dashboard-form .form-group {
    position: relative;
}

.time-right-icon::after,
.date-right-icon::after {
    position: absolute;
    right: 10px;
    top: 7px;
    content: "";
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right top;
}

.time-right-icon::after {
    background-image: url(/assets/images/time-grey.png);
}

.date-right-icon::after {
    background-image: url(/assets/images/date-grey.png);
}

/* Commmon Forms CSS End */
/* Header CSS Start */
img {
    width: auto;
    max-width: 100%;
}

.main-header.fixed {
    background-color: #fff;
    width: 100%;
    position: fixed;
    box-shadow: 4px 4px 8px #ddd;
    z-index: 2;
}

.main-header {
    position: fixed;
    width: 100%;
    z-index: 1;
}

.navbar-custom {
    padding: 0;
    transition: all .4s;
}

.navbar-custom .navbar-brand {
    padding: 0;
    margin: 0;
}

.navbar-custom .navbar-brand img {
    max-width: 140px;
}

.navbar-custom .navbar-collapse {
    justify-content: flex-end;
}

.navbar-custom .nav-link {
    line-height: 74px;
    color: #000;
    position: relative;
}

.navbar-custom .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 5px;
    left: 0;
    top: 0;
    background: #FFAA00;
    border-radius: 0 0 8px 8px;
    transition: all .4s;
}

.navbar-custom .nav-link.active::before {
    width: 100%;
}

.banner-section {
    background: linear-gradient(173.96deg, rgba(143, 137, 245, 0.24) 29.08%, rgba(255, 255, 255, 0) 95.21%);
    padding: 60px 0 60px;
    min-height: 780px;
    display: flex;
    align-items: center;
}

.static-col {
    position: unset;
}

.navbar.navbar-custom .navbar-nav .nav-link {
    line-height: 74px;
    color: #000;
    position: relative;
    padding: 0 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.navbar.navbar-custom .navbar-nav .nav-link.active {
    font-weight: bold;
}

.navbar .navbar-toggler {
    color: #000;
    padding: 0;
}

.lines {
    width: 25px;
    display: block;
    position: relative;
    height: 16px;
    transition: all .5s ease;
}

.navbar-toggler span {
    height: 3px;
    width: 100%;
    background-color: #000;
    display: block;
    margin-bottom: 5px;
    transition: transform .5s ease;
}

.internal-header .navbar-toggler span {
    background-color: #FFF;
}

.navbar-toggler[aria-expanded=true] span {
    position: absolute;
    transition: transform .5s ease;
}

.navbar-toggler[aria-expanded=true] span:first-child {
    top: 7px;
    transform: rotate(45deg);
    transition: transform .5s ease;
}

.navbar-toggler[aria-expanded=true] span:nth-child(2) {
    visibility: hidden;
}

.navbar-toggler[aria-expanded=true] span:last-child {
    width: 100%;
    top: 7px;
    transform: rotate(-45deg);
    transition: transform .5s ease;
}

/* Header CSS End */

/* Banner CSS Start */
.banner-images {
    position: absolute;
    right: 0;
    display: inline-block;
    width: auto;
    max-width: 45vw;
    top: 50%;
    transform: translateY(-50%);
}

.corner-banner {
    position: absolute;
}

.corner-banner.top {
    top: 8px;
    left: -45px;
}

.corner-banner.bottom {
    bottom: -50px;
    right: calc(100% - 140px);
}

.banner-title {
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 77px;
}

.separator {
    width: 100%;
    line-height: 0;
}

.separator span {
    height: 5px;
    width: 130px;
    background-color: #FFA907;
    display: inline-block;
}

.banner-subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    max-width: 407px;
    margin-top: 10px;
}

.banner-btn {
    background-color: #8E88F4;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 16%);
    border-radius: 6px;
    padding: 14px 34px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    display: inline-block;
    color: #fff;
    font-weight: bold;
    transition: all .4s;
}



.banner-btn:hover {
    background-color: #4c45ca;
    color: #FFF;
    text-decoration: none;
}

/* Banner CSS End */
/* Card Section CSS Start */
.section {
    position: relative;
}

.section.card-section {
    position: relative;
    padding: 80px 0;
}

.card-section .card-main {
    background: rgba(251, 248, 248, 0.8);
    box-shadow: 2px 4px 12px rgb(0 0 0 / 12%);
    border-radius: 12px;
    border: medium none;
    padding: 46px 25px 15px;
    margin-top: 30px;
    transition: all .4s;
    max-width: 280px;
    position: relative;
    margin-inline: auto;
    display: inline-block;
}

.card-section .card-main:hover,
.card-section .card-main:active,
.card-section .card-main:focus {
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 16px 48px rgba(0, 0, 0, 0.2);
}

.card-section .custom-width {
    max-width: 310px;
}

.card-section .separator span {
    height: 4px;
    width: 40px;
    margin-bottom: 12px;
}

.card-section .card-title {
    text-transform: capitalize;
    font-family: 'Gilroy', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000;
}

.card-content p {
    font-size: 14px;
    line-height: 20px;
    max-width: 240px;
    margin-inline: auto;
    min-height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 400;
    color: #000;
}

.account-structure .comment-col {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.card-icon {
    min-height: 85px;
}

.common-circle {
    position: absolute;
    left: 0;
    top: 0;
}

.layout-circle2 {
    top: 0;
}

.layout-circle {
    top: 150px;
}

.subtitle-commom {
    font-size: 56px;
    line-height: 68px;
    font-weight: bold;
}

.card-section .subtitle-commom {
    margin-inline: auto;
    max-width: 640px;
    text-align: center;
}

.section p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

/* Card Section CSS End */
/* Order Section CSS Start */
.section-shapes {
    min-height: 760px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.section-shapes .corner-left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 45vw;
}

.section-shapes .corner-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 45vw;
}

.section-img {
    z-index: 1;
}

.coming-soon-section img {
    height: calc(100vh - 75px);
    width: 100vw;
    margin-top: 74px;
}

/* Order Section CSS End */
/* Footer CSS Start */
.footer-main {
    background: linear-gradient(to top, rgba(143, 137, 245, 0.24) 40%, rgba(255, 255, 255, 0) 60%);
    /* background: linear-gradient(189.58deg, rgba(255, 255, 255, 0) 87.04%),rgba(143, 137, 245, 0.24) 30.43%; */
    padding-bottom: 80px;
    padding-top: 100px;
}

.footer-main .subtitle-commom {
    margin-bottom: 12px;
}

.footer-main p {
    margin: 0 auto;
    max-width: 720px;
    font-size: 18px;
}

.widget-listing {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget-listing .single-widget+.single-widget {
    margin-left: 15px;
}

.single-widget a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #8E88F4;
    color: #fff;
    opacity: .75;
    font-size: 18px;
}

.single-widget a:hover {
    text-decoration: none;
    background: #4c45ca;
}

.newsletter-form {
    margin-top: 35px;
    margin-bottom: 140px;
}

.newsletter-form .form-control {
    max-width: 440px;
    padding: 8px 15px 16px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #FBD0BA;
    border-radius: 8px;
    font-weight: 400;
    font-size: 20px;
    display: flex;
}

.newsletter-form .form-control:focus {
    outline: 0 none;
    box-shadow: unset;
}

.newsletter-form .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.newsletter-form .form-btn {
    background: #8E88F4;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 16%);
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 16px 32px;
    margin-left: 20px;
    border: 2px solid #8E88F4;
    transition: all .4s;
}

.newsletter-form .form-btn:hover {
    background: #FFF;
    color: #8E88F4;
}

.footer .footer-text {
    color: #8E88F4;
    margin-top: 10px;
}

/* Footer CSS End */
/* Custom Fle Uploads Start*/
.batch-file-info {
    padding-left: 30px;
}

.upload-file-custom {
    border: 2px dashed #8E88F4;
    border-radius: 8px;
    position: relative;
    margin: 0;
    width: 100%;
    overflow: hidden;
    height: 146px;
}

.data-validation .upload-file-custom {
    height: 482px;
}

.upload-file-custom img {
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.upload-file-custom input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.upload-placeholder {
    position: absolute;
    border-radius: 6px;
    margin: 6px;
    text-align: center;
    width: 100%;
    background-color: #E9E8FB;
    max-width: calc(100% - 12px);
}

.image-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #e9e8fb;
}

.batch-upload li {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #000;
}

.batch-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 0;
    display: inline-block;
    max-width: 250px;
}

.batch-file-info h3:not(.batch-title) {
    font-size: 18px;
}

.batch-file-info .modal-title.line-title {
    max-width: 400px;
}

.modal.show {
    display: block;
    background: #6e6e6e6e;
}

.upload-placeholder .icon {
    padding: 13px 0 10px;
}

.upload-ticket .upload-placeholder .icon {
    padding: 28px 0 10px;
}

.patients-list .upload-ticket .upload-placeholder .icon {
    padding: 35px 0 10px;
}

.upload-placeholder .icon span:first-child:first-child {
    color: #46464B;
    font-size: 36px;
}

.upload-placeholder span {
    display: block;
    font-size: 18px;
    text-align: center;
    color: #000000;
    padding-bottom: 14px;
}

.upload-placeholder .upload-extensions span {
    font-weight: 400;
    font-size: 13px;
    line-height: 48px;
    color: #000000;
    opacity: 0.6;
    margin-top: 30px;
}

.batch-upload .btn {
    margin-inline: 12px;
}

.upload-ticket {
    margin-top: 25px;
    margin-bottom: 15px;
}

.dashboard-form .upload-ticket textarea {
    height: 146px;
}

/* Custom Fle Uploads End*/
/* Form Common Design CSS Start */
.filters .col {
    flex: 0 0 0%;
}

.filters .form-group {
    width: 140px;
}

.edit-region {
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-right: 20px;
}

.btn-purple {
    background-color: #f4f2ff;
    color: #9582fc;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    padding: 8px 14px;
    text-align: left;
    position: relative;
    width: 190px;
    border-radius: 3px;
    cursor: auto;
}

.region-dropdown {
    line-height: 35px;
}

.region-dropdown .dropdown-toggle {
    font-size: 14px;
    line-height: 17px;
    padding: 8px 14px;
    text-align: left;
    position: relative;
}

.region-dropdown .dropdown-toggle:hover,
.region-dropdown .dropdown-toggle:active,
.region-dropdown .dropdown-toggle:focus {
    outline: 0 none;
    box-shadow: unset;
}

.region-dropdown .btn-orange {
    color: #FFAA00;
    background-color: #fff7e6;
}

.region-dropdown .btn-purple {
    background-color: #F4F2FF;
    color: #9582FC;
}

.region-dropdown .btn-red {
    background-color: #FFEDED;
    color: #F46A6A;
}

.region-dropdown .btn-green {
    background-color: #D5FEF9;
    color: #1CCCB7;
}

.region-dropdown .btn-white {
    border: 1px solid #44B5F8;
    background-color: #FFF;
    color: #010101;
}

.region-dropdown .dropdown-toggle::after {
    content: "";
    position: absolute;
    right: 10px;
    top: calc(50% + 2px);
    height: 14px;
    width: 18px;
    background-image: url(/assets/images/dropdown-arrow-grey.svg);
    background-repeat: no-repeat;
    font-size: 0px;
    transform: translateY(-50%);
    background-size: 14px;
}

.region-status .circle {
    display: inline-block;
    background: #FFAA00;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 22%);
    height: 16px;
    width: 16px;
    border-radius: 50%;
}

.edit-region .edit-pencil {
    background: #f4f3f3;
    border-radius: 6px;
    display: flex;
    height: 32px;
    width: 32px;
    align-items: center;
    justify-content: center;
    color: #010101;
    font-size: 18px;
}

/* Form Common Design CSS End */
/* Common popup Design Start */
.common-modal .modal-content {
    border-radius: 12px !important;
    overflow: hidden;
}

.common-modal .modal-header {
    border-bottom: 1px solid #e6e6e6;
    justify-content: center;
}

.common-modal .modal-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
}

.line-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 0;
}

.line-title::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 64px;
    height: 4px;
    background-color: #FFA907;
}

.batch-title.line-title {
    margin-bottom: 20px;
}

.modal .batch-title.line-title {
    font-size: 24px;
    line-height: 32px;
    max-width: 300px;
}

.common-modal .modal-footer .btn {
    border-radius: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    min-width: 124px;
}

.modal-footer .btn-cancel {
    background-color: #B9B9B9;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
    color: #fff;
    font-weight: bold;
}

.modal-footer .btn-create {
    background-color: #8E88F4;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
}

.modal-footer .btn-cancel:hover {
    background-color: #817d7d;
}

.modal-footer .btn-create:hover {
    background-color: #453fae;
}

.common-modal .modal-footer {
    background-color: #F5F5F5;
    justify-content: center;
}

/* Common popup Design End */
/* Common chat module design Start */
.chat-wrapper {
    background: #EEFEFD;
    border-radius: 12px;
}

.chat-title {
    font-size: 18px;
    text-align: center;
    padding: 16px 0px;
}

.chat-header {
    border-bottom: 2px solid #D1DFDF;
    text-transform: capitalize;
}

.inline-icon .upload-placeholder {
    margin: 2px;
}

.inline-icon .upload-placeholder .icon {
    padding: 13px;
    display: flex;
    align-items: center;
}

.inline-icon .upload-placeholder span {
    padding-bottom: 0px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
}

.inline-icon .upload-placeholder span {
    font-size: 18px;
    margin-right: 10px;
}

.dashboard-form .upload-file-custom.inline-icon {
    height: 52px;
}

.inline-icon .upload-placeholder {
    margin: 2px;
    max-width: calc(100% - 4px);
}

.dashboard-form .upload-file-custom.inline-icon {
    height: 52px;
}

.announcement-modal .dashboard-form .p-calendar .p-inputtext {
    padding: 10px 15px;
}

.announcement-modal .time-right-icon:after,
.announcement-modal .date-right-icon:after {
    top: 11px;
}

.sent-msg figure {
    display: none;
}

.message-data {
    display: flex;
    align-items: center;
}

.each-message {
    max-width: 70%;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
}

.sent-msg {
    float: right;
}

.chat-listing {
    padding: 30px 30px 0;
    min-height: 510px;
    overflow-y: auto;
    height: 520px;
    margin-bottom: 20px;
}

.received-msg .message-data {
    max-width: calc(100% - 52px);
}

.sent-msg .chat-content {
    background: #8F89F5;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 16px;
    border-radius: 18px 20px 0;
    margin-left: 12px;
    max-width: 372px;
}

.received-msg .chat-content {
    background-color: #fff;
    color: #000;
    padding: 16px;
    border-radius: 18px 18px 18px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    max-width: 357px;
    margin-right: 12px;
}

.message-data p {
    margin-bottom: 0;
    word-break: break-all;
}

.sent-msg .message-data {
    flex-direction: row-reverse;
}

.received-msg .message-content {
    display: flex;
    align-items: flex-start;
}

.received-msg figure {
    margin: 0;
}

.received-msg figure img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 12px;
}

.chat-message {
    padding: 0px 30px 30px 30px;
}

.chat-message .form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-message .form-group .attachment-btn {
    min-width: 48px;
    border-radius: 6px;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 16%);
}

.chat-message .form-group .send-msg-btn {
    height: 48px;
    background: #FFAA00;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 16%);
    border-radius: 6px;
    width: 48px;
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-message textarea.form-control {
    box-shadow: 0px 8px 24px rgb(0 0 0 / 10%);
    border-radius: 8px;
    max-height: 48px;
    resize: none;
    overflow: hidden;
    max-width: calc(100% - 70px);
    line-height: 32px;
}

.status-opt-out {
    font-size: 1rem;
    width: 100%;
    max-width: 134px;
    line-height: 1.5;
    background-color: #ffdada;
    border-color: #ffdada;
    color: #ff1616;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0 8px
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider.round {
    border-radius: 24px;
}

.slider.round:before {
    border-radius: 50%;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #8E88F4;
}

input:focus+.slider {
    box-shadow: 0 0 1px #8E88F4;
}

input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

.unauthorized {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #787878a3;
    color: #fff;
    text-align: center;
    font-weight: 600;
    padding: 20px;
}

.decimal-style {
    list-style: decimal;
    padding-left: 20px;
}

.decimal-style li {
    list-style: decimal;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #010101;
    margin-bottom: 8px;
}

.bottom-border-wrap span {
    white-space: nowrap;
    margin-right: 6px;
    display: inline-block;
    margin-top: 15px;
}

.bottom-border-wrap .form-control {
    border: medium none;
    border-bottom: 1px solid #666;
    border-radius: 0;
    max-height: 30px;
}

.bottom-border-wrap .form-control:hover,
.bottom-border-wrap .form-control:active,
.bottom-border-wrap .form-control:focus {
    outline: 0 none;
    box-shadow: unset;
}

.bar-code-media.disabled {
    position: relative;
}

.bar-code-media.disabled .unauthorized {
    display: flex;
    z-index: 1;
}
.bar-code-media.disabled img {
    filter:blur(5px);
}

.oprationhr .switch {
    height: 21px;
}

.oprationhr .slider:before {
    bottom: 3px;
}

.oprationhr input+.slider {
    background-color: #ff0000;
}

.oprationhr input:checked+.slider {
    background-color: #28a745;
}

.request-popup {
    position: relative;
}

.request-popup .request-dot {
    width: 16px;
    height: 16px;
    background: #FFAA00;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 22%);
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 41px;
}

.request-open {
    background: #FFFFFF;
    box-shadow: 0 4px 12px #00000038;
    padding: 5px 20px;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    right: 0;
    z-index: 1;
    top: 100%;
    width: auto;
    max-width: 100%;
    min-width: 180px;
}

.request-content p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.request-content p small {
    font-weight: 700;
    font-size: 11px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    opacity: 0.6;
}

.request-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 2px;
}

.request-btns::after {
    position: absolute;
    content: "";
    height: 28px;
    width: 1px;
    background-color: #ccc;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.request-btn {
    font-size: 13px;
    line-height: 30px;
    font-weight: 600;
}

.request-btns .cancel-request {
    color: #FD7171;
}

.request-btns .accept-request {
    color: #07A407;
}



.request-open.op-display .op-item small {
    padding: 5px 8px;
}

.request-open.arrow_box:after {
    right: 10px;
}

.setting-nav-tabs .p-overlay-badge .p-badge {
    right: -12px;
}
.p-badge.p-badge-danger {
    background-color: #ff0000;
    color: #fff;
}
.text-danger {
    color: #ff0000 !important;
}

.dashboard-form .phone-code-group .form-control {
    padding-left: 45px;
}

.phone-code {
    border: medium none;
    position: absolute;
    left: 0;
    bottom: 0px;
    width: auto;
    max-width: 40px;
    background-color: transparent;
    height: 47px;
    text-align: center;
    border-right: 1px solid #7f7f7f5c;
}

.dashboard-form .phone-code-group .phone-code:hover,
.dashboard-form .phone-code-group .phone-code:active,
.dashboard-form .phone-code-group .phone-code:focus {
    outline: 0 none;
    box-shadow: unset;
}

.dashboard-form .switch.switch-info {
    width: 80px;
    text-align: right;
    position: relative;
}

.switch.switch-info .slider {
    right: 0;
    width: 80px;
    left: unset;
}

.switch-info .text-switch {
    position: absolute;
    right: 5px;
    top: 0px;
    color: #fff;
    text-transform: capitalize;
}

.oprationhr input:checked~.text-switch {
    left: 5px;
    text-align: left;
}

.switch-info input:checked+.slider:before {
    transform: translate(55px);
}

.mini.switch.switch-info .slider {
    right: 0;
    width: 50px;
    left: unset;
}

.mini.switch-info input:checked+.slider:before {
    transform: translate(27px);
}

.oprationhr input:checked~.text-switch .switch-off {
    display: none;
}

.oprationhr input~.text-switch .switch-on {
    display: none;
}

.oprationhr input:checked~.text-switch .switch-on {
    display: inline-block;
}

.oprationhr tr.closed .p-calendar .p-inputtext {
    background-color: #eee;
}

/* Common chat module design End */
.dashboard-form .dropdown-toggle{
    position: relative;
}
.dashboard-form .dropdown-toggle:after {
    position: absolute;
    right: 10px;
    top: 50%;
    height: 14px;
    width: 18px;
    background-image: url(/assets/images/dropdown-arrow.png);
    background-repeat: no-repeat;
    font-size: 0px;
    transform: translateY(-50%);
    margin-top: 2px;
}
.dashboard-form .dropdown-toggle.no-arrow:after {
    background-image: none;
}
.region-dropdown .dropdown-toggle.no-arrow:after {
    background-image: none !important;
}
/* Order Lens CSS Start */
.order-lens-modal .modal-body {
    padding: 0;
}

.order-lens-modal .common-modal .modal-header {
    justify-content: flex-start;
}

.order-lens-modal .btn-close {
    background: unset;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -8px;
    top: -8px;
    margin: 0;
    opacity: 1;
    width: 20px;
    height: 20px;
}

.order-lens-modal .btn-close:hover,
.order-lens-modal .btn-close:active,
.order-lens-modal .btn-close:focus {
    outline: 0 none;
    box-shadow: unset;
}

.order-lens-modal .modal-title {
    text-transform: capitalize;
}

.order-lens-modal .dashboard-form .dropdown-toggle:after {
    position: absolute;
    right: 5px;
    top: 50%;
    height: 10px;
    width: 18px;
    background-image: url(/assets/images/dropdown-arrow.png);
    background-repeat: no-repeat;
    font-size: 0px;
    transform: translateY(-50%);
}

.order-lens-modal .filter-custom-width {
    max-width: 170px;
}

.order-lens-modal .filter-custom-width .dropdown-toggle {
    font-size: 14px;
    text-transform: capitalize;
}

.order-lens-modal .dashboard-form .table tbody tr td {
    text-transform: capitalize;
}

.order-lens-modal .dashboard-form .table .dropdown-toggle {
    padding: 0;
    border: medium none;
    background-color: #fff;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000 !important;
    text-transform: capitalize;
}

.order-lens-modal .dashboard-form .table .dropdown-toggle:hover,
.order-lens-modal .dashboard-form .table .dropdown-toggle:active,
.order-lens-modal .dashboard-form .table .dropdown-toggle:focus {
    outline: 0 none;
    box-shadow: unset;
}

.order-lens-modal .dashboard-form .table .dropdown-toggle::after {
    display: none;
}

.order-lens-modal .common-modal .modal-footer {
    border-radius: 0 0 8px 8px;
}

/* .order-lens-modal .table-common thead tr th:first-child, .order-lens-modal .table-common tbody tr td:first-child {
    padding-left: 40px !important;
}
.order-lens-modal .table-common thead tr th:last-child, .order-lens-modal .table-common tbody tr td:last-child {
    padding-right: 40px !important;
} */
.show-details {
    padding: 0 15px;
}

.cart-bg {
    background-color: #dcfdfb;
    border-radius: 12px 12px 0 0;
    padding: 24px 30px;
    border-top: 4px solid #8E88F4;
}

.order-lens-modal .table-common tr {
    position: relative;
}

.remove-row {
    position: absolute;
    padding: 0;
    max-width: 20px;
    right: -8px;
    top: -5px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-lens-modal .dashboard-form .table .count-dropdown .dropdown-toggle {
    color: #8E88F4 !important;
    border: 1.5px solid #8E88F4;
    border-radius: 6px;
    text-align: center;
    padding: 4px 1px;
    max-width: 56px;
}

.action-btns {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 15px;
}

.action-btns .btn.copy-row-btn,
.action-btns .btn.new-row-btn {
    padding: 7px 14px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    border-radius: 6px;
    min-width: 110px;
    text-align: center;
}

.action-btns .btn.copy-row-btn {
    color: #A3A3A3;
    border: 1.5px solid #A3A3A3;
}

.action-btns .btn.new-row-btn {
    border: 1.5px solid #8E88F4;
    color: #8E88F4;
    margin-left: 10px;
}

.action-btns .cart-row-btn {
    margin-left: auto;
    background: #8E88F4;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 16%);
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 14px;
    padding: 8px 19px;
    font-weight: 700;
}

.show-details .table-common {
    border-collapse: separate;
    border-spacing: 0 15px;
    margin: 0;
}

.show-details .table-common tbody tr:nth-child(even) {
    background-color: #fff;
}

.added-cart-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000;
    margin-bottom: 10px;
}

.empty-cart-info .added-cart-title {
    margin-bottom: 30px;
}

.empty-cart-info .cart-content {
    text-align: center;
}

.empty-cart-info .cart-content h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

.empty-cart-info .cart-content p {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
    opacity: 0.6;
}

.each-order {
    background-color: #ECFFFE;
    border: 1px solid #E2E9F8;
    border-radius: 8px;
    padding: 10px 16px;
}

.order-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-title-top {
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #0A0A0A;
    text-transform: capitalize;
    margin: 0;
}

.order-actions .btn {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #8E88F4;
    text-transform: uppercase;
    padding: 0;
}

.order-actions .edit-order-action {
    color: #8E88F4;
}

.order-actions .delete-order-action {
    color: #FF2E2E;
    margin-left: 24px;
}

.order-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

.order-detail-each {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
}

.order-detail-each .detail-listing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
}

.order-detail-each .order-label {
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    color: #000000;
    opacity: 0.6;
    text-transform: capitalize;
}

.order-detail-each .order-specification {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    text-transform: capitalize;
}

.order-detail-each .order-specification.color-primary {
    color: #8e88f4;
}

.each-order+.each-order {
    margin-top: 16px;
}

.cart-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.cart-content {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 16%);
    border-radius: 8px;
    margin-top: 16px;
    padding: 14px 16px;
}

.cart-content .cart-subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #000000;
    margin: 0;
}

.order-total {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    border-top: 1px solid #ccc;
    padding-top: 6px;
    margin-top: 6px;
}

.shipping-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    position: relative;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

.shipping-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 30px;
    background-color: #FFAA00;
}

.order-cart .cart-update-btn {
    border: 2px solid #8E88F4;
    filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.16));
    border-radius: 8px;
    width: 100%;
    margin-top: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #8E88F4;
    padding: 13px 10px;
}

.order-cart .cart-update-btn:hover,
.order-cart .cart-update-btn:active,
.order-cart .cart-update-btn:focus {
    outline: 0 none;
    background-color: #453fae;
    color: #fff;
    border-color: #453fae;
}

.order-cart .goto-checkout-btn {
    background-color: #8E88F4;
    color: #fff;
    padding: 13px 10px;
    border-radius: 8px;
    width: 100%;
    margin-top: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    border: 2px solid #8E88F4;
}

.action-btns .btn.new-row-btn:hover,
.action-btns .btn.new-row-btn:active,
.action-btns .btn.new-row-btn:focus,
.action-btns .cart-row-btn:hover,
.action-btns .cart-row-btn:Active,
.action-btns .cart-row-btn:focus,
.order-cart .goto-checkout-btn:hover,
.order-cart .goto-checkout-btn:active,
.order-cart .goto-checkout-btn:focus {
    background-color: #453fae;
    border-color: #453fae;
    color: #fff;
}

.action-btns .btn.copy-row-btn:hover,
.action-btns .btn.copy-row-btn:active,
.action-btns .btn.copy-row-btn:focus {
    background-color: #a3a3a3;
    color: #fff;
}

/* Order Lens CSS End */
/* Responsive CSS Start */
@media screen and (min-width: 1200px) {
    .card-section .container {
        max-width: 1140px;
    }

}

@media screen and (max-width: 1199.98px) {
    .section-shapes {
        min-height: 580px;
    }

    .footer-main {
        padding-top: 0;
    }

    .banner-images {
        max-width: 40vw;
    }

    .banner-section {
        min-height: 640px;
    }

    .corner-banner.bottom {
        bottom: -30px;
        right: calc(100% - 90px);
        max-width: 200px;
    }

    .corner-banner.top {
        max-width: 160px;
    }

    .banner-title {
        font-size: 54px;
        line-height: 60px;
    }

    .navbar-inner .nav-link img {
        margin-right: 5px;
        max-width: 15px;
    }

    .navbar-nav .nav-item+.nav-item {
        margin-left: 5px;
    }

    .navbar-inner .nav-link {
        padding: 0px 5px;
    }

    .nav-brand .dropdown-toggle {
        font-size: 16px;
    }
}

@media screen and (max-width: 991.98px) {

    .section-shapes .corner-left,
    .section-shapes .corner-right {
        max-height: 90%;
    }

    .section-shapes {
        min-height: 520px;
    }

    .subtitle-commom {
        font-size: 44px;
        line-height: 55px;
    }
}

@media screen and (max-width: 767.98px) {
    .newsletter-form .form-control {
        max-width: calc(100% - 200px);
    }

    .banner-images {
        max-width: 70vw;
        position: relative;
        transform: unset;
        top: unset;
        margin-top: 30px;
    }

    .main-header.fixed {
        top: -2px;
    }

    .section-shapes {
        padding: 20px 0;
    }

    .section-shapes .animate-img .corner-left,
    .section-shapes .animate-img .corner-right {
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
    }

    .section-shapes .section-shape {
        top: unset;
        transform: unset;
        max-width: 45vw;
        bottom: 0px;
    }

    .section-shapes .corner-left.section-img,
    .section-shapes .corner-right.section-img {
        max-width: 100%;
    }

    .flex-md-col-reverse {
        flex-direction: column-reverse;
    }

    .section-shapes p {
        margin-bottom: 25px;
    }

    .corner-banner.bottom {
        right: calc(100% - 145px);
    }

    .navbar.navbar-custom .navbar-nav .nav-link {
        line-height: 50px;
        display: inline-block;
        padding: 0 0px;
    }

    .main-header {
        z-index: 2;
        background-color: #fff;
    }

    .navbar-custom {
        padding: 5px 0;
    }

    .main-header .col-md-12 {
        padding: 0;
    }

    .navbar-custom .nav-link::before {
        top: calc(100% - 5px);
    }
}

@media screen and (max-width: 575.98px) {
    .section-shapes .section-shape {
        max-width: 57vw;
    }
}

@media screen and (max-width: 480.98px) {
    .banner-title {
        font-size: 46px;
        line-height: 50px;
    }

    .subtitle-commom {
        font-size: 36px;
        line-height: 40px;
    }

    .section-shapes .section-shape {
        max-width: 60vw;
    }

    .banner-images {
        max-width: 100vw;
    }

    .corner-banner.top {
        left: -25px;
    }

    .corner-banner.bottom {
        right: unset;
        left: -25px;
        bottom: -60px;
    }

    .newsletter-form .form-group {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .newsletter-form .form-control {
        max-width: 100%;
    }

    .newsletter-form .form-btn {
        margin-top: 20px;
        margin-left: 0;
    }

    .common-modal .modal-footer .btn {
        min-width: 83px;
    }
}

/* Responsive CSS End */
.mini-loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #8e88f4;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin:0px auto;
  }
  
.load-more {
    background: #eee;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    color: #8c8c8c;
    margin-top: 20px;
    padding: 10px;
    opacity: 0.8
}

.load-more:hover {
    cursor: pointer;
    opacity: 1;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: rgba(54, 49, 49, 0.3);
    border-radius: 0px;
}

.dashboard-table .table-common {
    border-collapse: separate;
    border-spacing: 0;
}

.dashboard-table tbody tr:hover {
    border-radius: 12px;
    /* border: 1px solid #E2E9F8; */
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.10);
}

.dashboard-table tbody tr:hover td:nth-child(1) {
    border-radius: 10px 0px 0px 10px;
}

.dashboard-table tbody tr:hover td:nth-last-child(1) {
    border-radius: 0px 10px 10px 0px;
}

.dashboard-table td {
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dashboard-table td:nth-child(1) {
    width: 50px;
    max-width: 50px;
}

.dashboard-table td:nth-child(3) {
    width: 150px;
}

.integration-wrap {
    background-color: #EEFEFD;
    padding: 16px 24px;
    border-radius: 8px;
}

.viewsWrapper.integration-wrap {
    height: calc(100% - 30px);
}

.carret-dropdown .dropdown-toggle {
    padding-right: 15px;
}

.carret-dropdown .form-control {
    background-image: url(/assets/images/dropdown-arrow.png);
    background-repeat: no-repeat;
    background-position: 97% center;
    cursor: pointer;
}

.select-dropdown .form-control {
    background-image: url(/assets/images/dropdown-arrow-grey.svg);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 95% center;
    cursor: pointer;
}

.select-dropdown.single .form-control {
    background-image: none;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 95% center;
    cursor: pointer;
}

.detail-btn {
    border: solid 3px #ffac3d;
    padding: 0 5px;
    font-size: 20px;
    border-radius: 7px;
    color: #ffac3d;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    top: 4px;
    right: 0px;
    opacity: .9;
}

.detail-btn:hover {
    cursor: pointer;
    opacity: 1;
}

.detail-btn .fa-arrows-alt {
    transform: rotate(45deg);
}

.btn-success {
    color: #fff;
    background-color: #45b95a;
    border-color: #45b95a;
}

.btn-success:hover {
    color: #fff;
    background-color: #29963d;
    border-color: #319e45;
}

.btn-danger {
    color: #fff;
    background-color: #fa5f55;
    border-color: #fa5f55;
}

.btn-danger:hover {
    color: #fff;
    background-color: #e44a3f;
    border-color: #e74e43;
}

.alert-out {
    background-color: #eee;
}

.btn-grey {
    color: #666;
    background-color: #e9e9e9;
    border-color: #e9e9e9;
    font-weight: bold;
}

.btn-dark {
    background-color: #aaa !important;
    border-color: #bbb;
    font-weight: bold;
}


.btn-reason{
    min-width:160px;
    border-radius: 6px;
  }
.btn-reason.Invalid.Parameters{
    color: #fff;
    background-color: #FF4B4B;
    border-color: #FF4B4B;
}
.btn-reason.Address.Assistance{
    color: #fff;
    background-color: #FFA907;
    border-color: #FFA907;
}
.btn-reason.Insurance.Verification{
    color: #fff;
    background-color: #26A647;
    border-color: #26A647;
}
.btn-reason.Rx.Authorization{
    color: #fff;
    background-color: #00A0E4;
    border-color: #00A0E4;
}
.btn-reason.Miscellaneous{
    color: #fff;
    background-color: #BAB9B9;
    border-color: #BAB9B9;
}

.profile-wrap {
    height: 200px;
    width: 200px;
    background: #EEFEFD;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-wrap .img-bg {
    /* background: linear-gradient(139.23deg, #BDBAF5 13.87%, #8E88F4 84.09%); */
    /* border: 4px solid #FFFFFF; */
    /* width: calc(100% - 60px); */
    /* height: calc(100% - 60px); */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 22%);
}

.img-bg img {
    width: calc(100% - 50px);
    max-width: 100%;
    height: calc(100% - 50px);
}

/* Quote Modal Start */

.create-quote-modal .dashboard-form .form-group .form-control {
    border: 1px solid #7F7F7F;
    font-weight: normal;
}

.icon-group-currency span {
    position: absolute;
    right: 10px;
    top: 34px;
    font-size: 18px;
    color: #000;
    font-weight: 600;
    opacity: 0.6;
}

.common-modal.softcl-modal {
    width: 100%;
    max-width: 530px;
}

.common-modal.price-quote-modal {
    max-width: 880px;
    width: 100%;
}

.create-quote-modal.price-quote-modal .modal-body {
    padding: 30px 35px 10px;
}

.softcl-modal .time-icon-control {
    background-image: url(/assets/images/time-grey.png);
    background-repeat: no-repeat;
    background-position: 95%;
    background-size: 15px;
}

.quote-title {
    font-size: 20px;
    color: #010101;
    font-weight: 700;
    line-height: 32px;
}

.quote-subtitle {
    font-size: 12px;
}

.patient-info-content p {
    font-size: 12px;
    color: #747474;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    max-width: 280px;
}

.patient-info-content img {
    width: 90%;
    max-width: 1000px;
}

.lenses-both {
    padding: 14px 16px;
    border-radius: 12px;
    margin: 16px 0;
    background-color: #FFF;
    width: 100%;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 16px 0px #0000001F;
}

.lenses-table .logo-main {
    border-radius: 12px;
    height: 56px;
    width: 56px;
    padding: 6px;
    border: 1px solid #D6D6D6;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lenses-table .lens-title {
    color: #000;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.lenses-table .lens-info {
    color: #000;
    margin: 5px 0 0 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
}

.lenses-table .lens-label {
    color: #636363;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    display: block;
}

.lenses-table .lens-desc {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    display: block;
}

.lenses-table .lens-info .each-info {
    width: 100%;
    max-width: 33%;
    margin-top: 6px;
}

.pricing-table {
    border: 1px solid #F1F1F1;
    border-radius: 12px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.pricing-table.disabled {
    border: 1px solid #ccc;
    background-color: #F1F1F1;
}

.pricing-table.disabled .table-footer-content,
.pricing-table.disabled .table-header-content {
    background-color: #F1F1F1;
}

.pricing-table.disabled .table-header-content {
    border: 1px solid #ccc;
}

.pricing-table.disabled .table-main-content {
    padding-bottom: 0;
}

.pricing-table.disabled .table-main-content .net-price-text {
    margin-top: 0px;
}



.pricing-table.disabled .table-header-content h6,
.pricing-table.disabled .table-header-content small {
    color: #000;
}

.pricing-table .table-header-content {
    text-align: center;
    background-color: #8F89F5;
    border-radius: 12px;
    padding: 8px;
    width: calc(100% - 30px);
    margin-inline: auto;
    margin-top: -24px;
    line-height: 12px;
}

.pricing-table .table-header-content h6,
.pricing-table .table-header-content p {
    color: #fff;
    margin: 0;
}

.pricing-table .table-header-content h6 {
    font-weight: 700;
    font-size: 14px;
}

.pricing-table .table-header-content p {
    font-weight: 500;
    font-size: 11px;
}

.pricing-table .table-header-content small {
    color: #fff;
    font-size: 11px;
    font-weight: 500;
}

.table-main-content {
    padding: 16px 16px 8px 16px;
}

.table-main-content p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    font-weight: 500;
    line-height: 22px;
    color: #000;
    margin: 0;
}

.table-footer-content {
    background-color: #F6F5FF;
    padding: 12px 16px;
    border-radius: 0 0 12px 12px;
}

.table-footer-content p {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    line-height: 24px;
}

.net-price-text {
    border-top: 1px solid #F1F1F1;
    padding-top: 8px;
    margin-top: 8px;
}

.table-main-content .net-price-text {
    margin-top: 8px;
}

.table-footer-content .text-green {
    color: #00A700;
    font-size: 12px;
}

.pricing-table.yearly-pricing .table-footer-content.bg-green {
    background-color: #AEF8AE;
}

.footer-quotes-title {
    font-size: 16px;
    line-height: 32px;
    color: #010101;
    font-weight: 700;
    margin-bottom: 0;
}

.quotes-footer p {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #010101;
    margin-bottom: 8px;
}

.contact-footer ul {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 0;
}

.contact-footer ul::after {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    background-color: #ccc;
}

.contact-footer ul li {
    width: 100%;
    max-width: 50%;
    text-align: center;
}

.contact-footer ul li a {
    padding: 0 16px;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    position: relative;
    z-index: 1;
    background-color: #fff;
    align-items: center;
    justify-content: center;
}

.contact-footer ul li a span {
    padding-left: 16px;
}

.contact-footer ul li img {
    max-width: 24px;
}

.info-section {
    width: calc(100% - 70px);
    padding-left: 12px;
    position: relative;
}

.top-section {
    display: flex;
    align-items: center;
}

.tollfree-wrap {
    /* border: 1px solid #F1F1F1; */
    border-radius: 12px;
    padding: 8px 16px;
    position: relative;
    width: auto;
    /* margin: 0 auto; */
}

.tollfree-wrap .custom-col-main:first-child::before {
    border: 1px solid #F1F1F1;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    content: "";
}

.tollfree-wrap .custom-col-main {
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 270px;
}

.tollfree-wrap .custom-col-main+.custom-col-main {
    border-left: 1px solid #f1f1f1;
}

.tollfree-wrap-single {
    max-width: 300px;
    margin-inline: auto;
}

.tollfree-wrap-double {
    max-width: 600px;
    margin-inline: auto;
}

.tollfree-wrap.tollfree-wrap-single::before,
.tollfree-wrap.tollfree-wrap-single::after,
.tollfree-wrap.tollfree-wrap-double::after {
    display: none;
}

.tollfree-wrap.tollfree-wrap-double::before {
    left: 50%;
}

.tollfree-content p,
.tollfree-content strong,
.tollfree-content span {
    line-height: 15px;
    font-size: 14px;
    color: #000;
    margin: 0;
}

.tollfree-content p {
    font-weight: 500;
    padding-left: 6px;
}

.tollfree-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tollfree-left {
    display: flex;
    align-items: center;
}

.tollfree-left img {
    max-width: 25px;
}

.bar-code-media canvas {
    max-width: 140px;
    width: 90%;
}

.pill {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
    /* border: solid 1px #8f89f5; */
    color: #fff;
    padding: 2px 10px;
    border-radius: 15px;
    width: 55px;
    align-self: flex-start;
}

@media screen and (max-width: 767.98px) {

    .tollfree-wrap::before,
    .tollfree-wrap::after {
        display: none;
    }

    .tollfree-wrap {
        padding: 0 16px 16px;
    }

    .bar-code-media {
        text-align: center;
    }
}

@media screen and (max-width: 575.98px) {
    .contact-footer ul {
        padding: 0px 0 10px;
        flex-wrap: wrap;
    }

    .contact-footer ul::after {
        display: none;
    }

    .contact-footer ul li {
        max-width: 100%;
    }

    .contact-footer ul li+li {
        margin-top: 15px;
    }
}

.create-quote-modal .btn-close,
.soft-quote-modal .btn-close {
    font-size: 0px;
    opacity: 1;
    margin: 0;
    position: absolute;
    padding: 0;
    height: 24px;
    width: 24px;
    right: -7px;
    top: -10px;
    z-index: 1;
}

.create-quote-modal .btn-close:hover,
.soft-quote-modal .btn-close:hover,
.create-quote-modal .btn-close:active,
.soft-quote-modal .btn-close:active,
.create-quote-modal .btn-close:focus,
.soft-quote-modal .btn-close:focus {
    outline: 0 none;
    box-shadow: unset;
}

.create-quote-modal .btn-close img,
.soft-quote-modal .btn-close img {
    width: 24px;
    height: 24px;
}

.oley {
    position: relative;
    z-index: 2;
}

.oley .modal-body {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
}

.oley .detail-header {
    border-radius: 10px 10px 0 0 !important;
}

.oley::after {
    position: fixed;
    content: "";
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #010101c4;
    z-index: -1;
}

.soft-quote-modal.common-modal .modal-content,
.create-quote-modal.common-modal .modal-content {
    overflow: visible;
}

.office-select-group .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: #8e88f4;
}

.create-quote-modal.common-modal .modal-footer .btn {
    padding: 8px 14px;
}

.common-modal .modal-footer .btn {
    padding: 8px 14px;
}

.btn.autotrial {
    color: #9582fc;
    border: solid thin #9582fc;
    border-radius: 20px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight,
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #fff;
    background: #8e88f4;
}

.create-quote-modal .dropdown-menu {
    width: 100%;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
    text-align: center;
    outline: 0 none;
}

.p-datepicker-header .p-datepicker-decade {
    white-space: nowrap;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight,
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #fff;
    background: #8e88f4;
}

.create-quote-modal .dropdown-menu {
    width: 100%;
}

.calender-field .p-calendar .p-datepicker {
    min-width: 180px;
}

.calender-field .p-link:focus,
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus,
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus,
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus,
.p-datepicker table td>span.p-ripple:hover,
.p-datepicker table td>span.p-ripple:active,
.p-datepicker table td>span.p-ripple:focus {
    outline: 0 none;
    box-shadow: unset;
    box-shadow: unset;
}

.bar-code-media .qr-center-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.bar-code-media {
    position: relative;
    display: inline-block;
}

.powered-by p img {
    max-width: 70px;
    margin-left: 15px;
}

.powered-by p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 20px;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
}

.create-quote-modal .dropdown-item:hover,
.create-quote-modal .dropdown-item:active,
.create-quote-modal .dropdown-item:focus {
    color: #fff;
    background-color: #9581fb;
}

.create-quote-modal .patient-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0px;
}

.create-quote-modal .patient-info-content p {
    max-width: 340px;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button,
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: #8f89f5;
}

.cursor-auto {
    cursor: auto;
}

.swal2-input-label {
    font-size: 24px;
    font-weight: bold;
}

.mtoNote {
    font-weight: bold;
    font-size: 12px;
    margin: 5px;
    color: red;
}

.mto-place {
    width: 150px;
    height: 150px;
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mto-stripe {
    position: absolute;
    background-color: red;
    color: #fff;
    padding: 0 55px;
    transform: rotate(-11deg);
    top: 63%;
    left: 14%;
}

.quotes-footer {
    padding-left: 16px;
}

@media print {
    .tollfree-wrap .custom-col-main {
        min-width: 220px;
    }

    .quotes-footer {
        padding-left: 0px;
    }

    .pricing-table {
        margin-bottom: 5px !important;
    }

    .quote-title {
        margin-bottom: 0px !important;
    }

    .unauthorized {
        font-size: 10px;
        width: 110px;
        height: 110px;
        left: 63%;
        background-color: #787878cc !important;
        background: #787878cc !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .mto-stripe {
        padding: 0 44px;
        top: 60%;
        left: 14%;
        background-color: red !important;
        background: red !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .pricing-table img {
        max-height: 300px;
    }

    .lenses-both {
        background-color: #FFF;
        border: 1px solid #eee;
        box-shadow: 0px 0px 0px 0px #000;
        margin: 8px 0;
    }

    .lenses-table .lens-title {
        font-size: 15px;
    }

    #printDiv {
        position: absolute;
        left: 0;
        top: 0;
    }

    .left-web-padding {
        padding-left: 0px;
    }

    #priceQuote.modal {
        display: block;
        visibility: visible;
    }

    .pdf-md-4 {
        max-width: 33.33%;
    }

    .table-row .pdf-md-4:first-child {
        padding-right: 0;
    }

    .table-row .pdf-md-4:nth-child(2) {
        padding: 0 8px;
    }

    .table-row .pdf-md-4:nth-child(3) {
        padding-left: 0;
    }

    .row.px-0.mt-4 {
        margin-left: 15px;
        margin-right: 15px;
        max-width: calc(100% - 30px);
    }

    .table-footer-content p {
        font-size: 11px !important;
        line-height: 18px !important;
    }

    .table-footer-content {
        padding: 12px 10px !important;
    }

    .table-main-content {
        padding: 16px 10px 8px !important;
    }

    .pdf-md-5 {
        max-width: 41.66%;
    }

    .pdf-md-6 {
        max-width: 50%;
    }

    .pdf-md-7 {
        max-width: 58.33%;
    }

    .pdf-md-8 {
        max-width: 66.66%;
    }

    .btn-close {
        display: none;
    }

    .pricing-table .table-header-content {
        /* background-color: #8f89f5 !important;
        background: #8f89f5 !important; */
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .table-footer-content {
        /* background-color: #f6f5ff !important;
        background: #f6f5ff !important; */
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .pricing-table.yearly-pricing .table-footer-content {
        background-color: #aef8ae !important;
        background: #aef8ae !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .pricing-table.yearly-pricing {
        /* background-color: #eefefd !important;
        background: #eefefd !important; */
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .pricing-table.disabled .table-footer-content,
    .pricing-table.disabled .table-header-content {
        background-color: #F1F1F1 !important;
        background: #F1F1F1 !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .pricing-table.disabled {
        background-color: #F1F1F1 !important;
        background: #F1F1F1 !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .lenses-table {
        background-color: #fff !important;
        background: #fff !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .text-md-end .patient-info-content {
        text-align: right;
    }

    .col-md-4.pdf-md-4.text-md-end.mt-md-0.mt-4,
    .col-md-3.text-md-end.mt-md-0.mt-4.pe-md-0 {
        margin-top: 0 !important;
    }

    .create-quote-modal.price-quote-modal .modal-body {
        padding: 10px 15px !important;
    }

    .tollfree-wrap {
        padding: 4px 25px !important;
    }

    .tollfree-wrap .col-md-4 {
        max-width: 33% !important;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-top: 8px !important;
        margin-bottom: 8px;
    }

    .pricing-table img {
        max-height: 300px;
    }

    .pricing-table {
        margin-bottom: 10px !important;
    }

    .row.px-0.mt-4 {
        margin-top: 10px !important;
    }

    .pricing-table {
        margin-top: 25px;
    }

    .table-main-content p {
        line-height: 15px !important;
    }

    .table-footer-content {
        padding: 8px 16px;
    }

    .table-main-content {
        padding: 8px 16px;
    }

    .contact-footer ul {
        padding: 4px 0 !important;
    }

    .powered-by p {
        padding: 0px 0 8px !important;
    }

    .row.px-0.mt-4 {
        margin-top: 10px !important;
    }

    .table-footer-content p {
        font-size: 12px;
        line-height: 16px;
    }

    .patient-info-content img {
        width: 90%;
        max-width: 60px !important;
        max-height: 60px !important;
    }

    .lenses-table .lens-info .each-info {
        margin-top: 2px;
    }

    .lenses-table .lens-label {
        font-size: 12px;
        line-height: 16px;
    }

    .lenses-table .lens-desc {
        font-size: 12px;
        line-height: 14px;
    }

    .lenses-table .logo-main {
        height: 40px;
        width: 40px;
    }

    .lenses-table .logo-main img {
        width: auto;
        max-width: 100%;
    }

    .quotes-footer p {
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 4px;
    }

    .row.px-0.mt-4 .col-md-9 {
        width: 75%;
        padding-right: 0;
    }

    .row.px-0.mt-4 .col-md-3 {
        width: 25%;
        padding-left: 0;
    }

    .bar-code-media {
        display: block !important;
        text-align: right !important;
    }

    .bar-code-media img {
        max-width: 120px !important;
        margin-left: auto;
    }

    .col-md-6.ps-md-0.pdf-md-7.text-md-end.mt-md-0.mt-4 .patient-info-content .patient-info-content p {
        position: relative;
        right: -10px;
    }

    .table-footer-content p {
        font-size: 12px;
    }
}

.full-height-border .p-carousel {
    border-left: 2px solid #D1DFDF;
}

.full-height-border .p-carousel .patient-description {
    border-left: medium none
}

.order-progress {
    margin-top: 10px;
    background-color: #F8F8F8;
}


.order-progress {
    margin-top: 10px;
    background-color: #F8F8F8;
}

.order-progress .progress-bar-2 {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    overflow-x: auto;
    padding: 20px 40px 40px;
    /* justify-content: center; */
}

.order-progress .progress-step {
    position: relative;
    padding-top: 40px;
    width: 160px;
}

.order-progress .progress-step:last-child {
    width: 80px;
}

.order-progress .step-line {
    height: 12px;
    width: 100%;
    position: absolute;
    left: 0;
    left: 12px;
    top: 14px;
}

.order-progress .step-icon {
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 6px;
    left: 0;
    opacity: 0.7;
}

.order-progress .active .step-icon {
    opacity: 1;
}

.order-progress .step-info {
    display: flex;
    align-items: center;
}

.order-progress .step-info p {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #000000;
    margin: 0;
    margin-left: -30px;
    min-height: 45px;
    padding-right: 10px;
}

.messsage-actions .save-btn img {
    max-width: 12px;
}

/* patient-detail */
.detail-section {
    background: #EEFEFD;
    border-radius: 12px;
    padding: 0 20px;
}

.patient-description {
    border-left: 2px solid #D1DFDF;
    padding: 10px 10px 30px 50px;
    display: flex;
    flex-wrap: wrap;
}

.patient-description .each-description {
    width: 100%;
    max-width: 33%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin-top: 22px;
}

.patient-info {
    padding: 12px 0px;
}

.patient-name {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #0A0A0A;
    margin: 0;
    text-transform: capitalize;
}

.patient-info li+li {
    margin-top: 6px;
}

.patient-info li span {
    font-size: 16px;
    line-height: 32px;
    color: #0A0A0A;
    display: inline-block;
    margin-left: 10px;
}

.patient-info li span {
    color: #8F89F5;
}

.relTop2.redbg {
    background: red;
}

.icon-group {
    position: relative;
}

.password-icon {
    position: absolute;
    bottom: 10px;
    right: 15px;
    cursor: pointer;
}

.upload-file-custom.active {
    border: none;
}

.upload-file-custom .loader img {
    object-fit: cover;
}

.upload-file-custom.active .upload-placeholder {
    background-color: transparent;
    display: none;
}

.text-limit-100 {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.text-limit-150 {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.text-limit-200 {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.consentForm {
    margin: 6px auto;
    padding: 12px 12px;
    border: 1px solid #DDDDDD;
}

.consentForm p {
    margin-bottom: 2px !important;
    font-size: 12px !important;
}

.consentForm ul {
    margin: 5px 0;
}

.consentForm li {
    margin-bottom: 2px !important;
    font-size: 12px !important;
    padding-left: 15px;
}

.consentForm li img {
    margin-right: 8px;
}

.consentForm .media-right img {
    max-height: 70px;
}

.consentForm .unordered-list {
    padding-left: 15px;
}

.consentForm .unordered-list li {
    list-style: disc;
    margin-left: 15px;
    padding-left: 5px;
}

.consentForm .printInput {
    border-bottom: 0.25px solid #aaa !important;
}

.consentForm .generated-preview {
    border-bottom: 0.25px solid #aaa !important;
    margin-left: 15px;
    display: block;
    width: 100%;
    top: 0;
}

.consentForm .delete-sign {
    padding: 0;
    border: 0;
    display: none !important;

}

.consentForm .space-top-10 {
    margin-top: 10px;
}

.no-data-wrapper {
    background-color: #eee;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.form-group {
    position: relative;
}
.icon-group-left .form-control {
    padding-left: 35px;
}
.icon-group-left .input-icon {
    position: absolute;
    left: 8px;
    top: 10px;
    max-width: 18px;
}
.icon-group-right .input-icon {
    position: absolute;
    right: 8px;
    max-width: 16px;
}
.table-filters .icon-group-right .input-icon {
    top: 10px;
}
.months-group {
    max-width: 110px;
}
.financial-information .fields .form-group:first-child {
    min-width: 270px;
}
.automated-inner {
    background-color: #EEFEFD;
    border: 1px solid #E2E9F8;
    border-radius: 8px;
    margin-bottom: 30px;
}
.right-wrap {
    display: flex;
    align-items: center;
}
.automated-title-wrap {
    border-bottom: 1px solid #E2E9F8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 20px;
}
.automated-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 48px;
    color: #0A0A0A;
    margin: 0;
}
.edit-msg a, .delete-msg a {
    background: #E5F2F1;
    border-radius: 6px;
    display: flex;
    height: 32px;
    width: 32px;
    align-items: center;
    justify-content: center;
}
.messsage-actions .edit-msg {
    margin: 0 8px;
}
.edit-msg a {
    background: #E5F2F1;
    color: #000;
}
.delete-msg a {
    background: #F0EEED;
    color: #FA5C5C;
}
.messsage-actions {
    display: flex;
    align-items: center;
}
.automated-inner .dropdown-toggle{
    position: relative;
    background-color: #E5F3FC;
    width: 116px;
    text-align: left;
    padding: 4px 10px 4px;
    font-weight: 700;
    font-size: 14px;
    line-height:22px;
    color: #8E88F4;
    text-transform: capitalize;
    display: block;
}
.automated-inner .dropdown-toggle:hover,
.automated-inner .dropdown-toggle:active,
.automated-inner .dropdown-toggle:focus{
    outline: 0 none;
    box-shadow: unset;
}
.automated-inner .dropdown-toggle::after {
    position: absolute;
    right: 10px;
    height: 14px;
    width: 18px;
    background-image: url(/assets/images/dropdown-arrow-grey.svg);
    background-repeat: no-repeat;
    font-size: 0px;
}
.automated-title{
    text-transform: capitalize;
}
.carddetails{
    color:#555;
    margin-bottom: 50px;
}
.carddetails span{
    margin:0px 30px;
}
.message-content{
    position: relative;
}
.message-content p {
    font-weight: 400;
    font-size: 14px;
    margin: 0;
}
.message-content .card-tag{
    position: absolute;
    background: #8E88F4;
    padding: 4px 10px;
    border-radius: 0px 4px 4px 4px;
    left: -10px;
    top: 50px;
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .message-content .card-tag:after{
    content: "";
    position: absolute;
    border: 4px solid #7c7c7c;
    left: 0;
    top: -8px;
    border-top-color: transparent;
    border-left-color: transparent;
  }
  
  .message-content .card-tag.orange{
    background: #FBA919;
  }
  .message-content .footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.message-content .footer .btn{
    background: #E5F2F1;
    color: #8E88F4;
}
  .card-brang-img {
    height: 32px;
}
.automated-inner.bank {
    background-color: #fff;
}

.card-plceholder{
    width: 100%;
    height: 200px;
    border: dotted 3px #8E88F4;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.card-plceholder-color{
    background: #E9E8FB;
    color: #8e88f4;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border: solid 5px #fff;
}
.child-lock{
    margin: -5px;
    width: calc(100% + 10px);
    height: 100%;
    background: #a5a5a54d;
    position: absolute;
    z-index: 1;
    border-radius: 5px;
}
.child-lock .fa-lock {
    font-size: 80px;
    padding: 30px 35px;
    background: #ffffffc9;
    border-radius: 50%;
    margin-top: 100px;
    border: solid 3px #888;
}
.nav-tabs{
    border-bottom: none;
}
.table-filters {
    margin-bottom: 16px;
    margin-top: 20px;
}
.btn-groups .input-icon {
    max-width: 16px;
    margin-right: 12px;
}
.action-column a {
    background-color: #E8E7FD;
    height: 28px;
    width: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 14px;
}
.legal-agreement-filters .btn-groups .filter-btn {
    font-weight: 600;
    color: #8E88F4;
}
.action-column a + a{
    margin-left: 16px;
}
.text-blue{
    color:#8e88f4;
}
.btn-blue{
    color: #E5F2F1 !important;
    background: #8E88F4 !important; 
}
#card-element{
    background-color: #EEFEFD;
    border: 1px solid #7f7f7f5c;
    border-radius: 8px;
    font-size: 15px;
    color: #000;
    padding: 8px;
}
.mini-loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #8e88f4;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin:0px auto;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
/* fixed column table start */
.fixed-col-table td,.fixed-col-table th{
    z-index: 1;
  }
  .fixed-col-table td:first-child,.fixed-col-table th:first-child{
    position: sticky;
    left: 0;
    z-index: 2;
    /* display: block; */
    min-width: 50px;
  }
  .fixed-col-table td:nth-child(2),.fixed-col-table th:nth-child(2){
    position: sticky;
    left: 42px;
    z-index: 2;
  }
  .fixed-col-table td:nth-child(3),.fixed-col-table th:nth-child(3){
    position: sticky;
    left: 270px;
    z-index: 2;
  }
  .dashboard-form .dispute-wrap .p-calendar .p-inputtext {
    /* line-height: 28px;
    border-radius: 8px;
    border: 1px solid #CDCDCD;
    background-color: #eefefd; */
    padding-right: 30px;
    background-image: url(/assets/images/date-icon.png);
    background-repeat: no-repeat;
    background-position: 98%;
    background-size: 20px;
    cursor: pointer;
}
.dropdown .dropdown-item.disabled.p-element{
         pointer-events: all;
         cursor: pointer;
}
  /* fixed column table end */
.updatelist li{
    padding:5px 10px;
}
.updatelist li:nth-child(even){
    background-color: #f8f8f8;
}
.updatelist li:hover{
    background-color: #e7e8fd;
    cursor: pointer;
}
  .date-cross{
    position: absolute;
    right: 15px;
    top: 11px;
    cursor: pointer;
}
@media screen and (min-width: 1201px) and (max-width: 1400px){
.table-common thead th {
    font-size: 14px !important;
}
.table-common tbody td {
    font-size: 12px !important;
}
.btn-batch.w120, .btn.btn-dispensejob {
    font-size: 12px;
    width: 100px;
}
}